import React, { useState, useMemo } from 'react'

import styled from 'styled-components'

//import { Marker } from 'react-google-maps'

import Container from '../components/Container'
import Layout from '../components/Layout'
//import Map from '../components/Map'

export default () => {
	const { honeyPot, setHoneyPot } = useState('')

	const [ email, setEmail ] = useState('')
	const [ name, setName] = useState('')
	const [ age, setAge ] = useState('')
	const [ wushuChecked, setWushuChecked ] = useState(false)
	const [ contortionChecked, setContortionChecked ] = useState(false)
	const [ gender, setGender ] = useState(undefined)
	const [ message, setMessage ] = useState('')
	const [ medicalConditions, setMedicalConditions ] = useState('')
	const [ phoneNumber, setPhoneNumber] = useState('')
	const [socialMediaUrl, setSocialMediaUrl] = useState('')

	const isFormValid = useMemo(() => (
		validateEmail(email) &&
		validatePhoneNumber(phoneNumber) &&
		name.length > 0 &&
		!isNaN(parseInt(age)) &&
		(wushuChecked || contortionChecked) &&
		(gender === 'male' || gender === 'female') &&
		message.length > 0 &&
		medicalConditions.length > 0 && 
		validateUrl(socialMediaUrl)
	), [email, name, age, wushuChecked, contortionChecked, gender, message, medicalConditions, phoneNumber, socialMediaUrl])


	return (
		<Layout>
			<ContactContainer>
{/*
				<MapSection>
					<Map apiKey="AIzaSyAgWWBSW7EbdwssOJK8ISm9TXNgS1ZhbnQ">
						{ cachedMarker }
					</Map>
				</MapSection>
*/}

				<DetailSection>
					<DetailIntroParagraph>
						<Bold>DiTan Wu Shu</Bold> is only looking for a new breed of students, adults and the young who will be committed, positive and hard working. Quality over quantity is our motto.
					</DetailIntroParagraph>

					<DetailIntroParagraph>
						<Bold>DiTan Contortion</Bold> is only offering private one hour sessions to females interested in learning artistic Mongolian contortion.
						{/*Head coach Amarsanaa <Important>only</Important> offers private one hour sessions to anyone interested in learning artistic 
						Mongolian contortion. To schedule a session, send us a message through the form in the contact page.*/}
					</DetailIntroParagraph>

					{/*<DetailContainer>
						<Title>Class schedule</Title>
						<Bold>Monday</Bold> &amp; <Bold>Thursday</Bold>, 6:30pm - 8:30pm
					</DetailContainer>*/}

					{/*<DetailContainer>
						<Title>Location</Title>
						<div>Currently virtual (through Zoom)</div>

						<Bold>Long Island City School of Ballet</Bold>
						<div>44-02 23rd St, Room #210</div>
						<div>Long Island City, NY 11101</div>

					</DetailContainer>

					<DetailContainer>
						<Title>Phone</Title>
						(718) 504 - 2981
					</DetailContainer>*/}
				</DetailSection>

				<ContactSection>
					<Title style={{ marginBottom: 28 }}>Get in touch</Title>
					<RequiredMessage>
						<RequiredAsterisk /><RequiredAsterisk /> All fields are required <RequiredAsterisk /><RequiredAsterisk />
					</RequiredMessage>
					<form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
						<p hidden>
							<label>
								Don't fill this out:{" "}
								<input
									name="bot-field"
									value={ honeyPot }
									onChange={ event => setHoneyPot(event.target.value) }
								/>
							</label>
						</p>
						<input type="hidden" name="form-name" value="contact" />

						<Label htmlFor="email">Email</Label>
						<Input
							name="email"
							type="text"
							value={ email }
							onChange={ event => setEmail(event.target.value) }
						/>

						<Split>
							<NameContainer>
								<Label htmlFor="name">Name</Label>
								<Input
									name="name"
									type="text"
									value={ name }
									onChange={ event => setName(event.target.value) }
								/>
							</NameContainer>
							<AgeContainer>
								<Label htmlFor="age">Age</Label>
								<Input
									name="age"
									type="number"
									placeholder="99"
									value={ age }
									onChange={ event => setAge(event.target.value) }
								/>
							</AgeContainer>
						</Split>

						<Split>
							<div>
								<EnqueryLabel htmlFor="enquery-type">Inquiring about</EnqueryLabel>
								<Enqueries>
									<Enquery>
										<EnqueryType>Wushu</EnqueryType>
										<EnqueryCheckbox
											type="checkbox"
											name="wushu"
											checked={ wushuChecked }
											onChange={ () => setWushuChecked(!wushuChecked) }
										/>
									</Enquery>
									<Enquery>
										<EnqueryType>Contortion</EnqueryType>
										<EnqueryCheckbox
											type="checkbox"
											name="contortion"
											checked={ contortionChecked }
											onChange={ () => setContortionChecked(!contortionChecked) }
										/>
									</Enquery>
								</Enqueries>
							</div>
							<div>
								<EnqueryLabel htmlFor="gender-type">Gender</EnqueryLabel>
								<Enqueries>
									<Enquery>
										<EnqueryType>Male</EnqueryType>
										<EnqueryCheckbox
											type="radio"
											name="male"
											value="male"
											checked={ gender === 'male' }
											onChange={ event => setGender(event.currentTarget.value) }
										/>
									</Enquery>
									<Enquery>
										<EnqueryType>Female</EnqueryType>
										<EnqueryCheckbox
											type="radio"
											name="female"
											value="female"
											checked={ gender === 'female' }
											onChange={ event => setGender(event.currentTarget.value) }
										/>
									</Enquery>
								</Enqueries>
							</div>
						</Split>

						<Label htmlFor="message">Message</Label>
						<TextArea
							name="message"
							value={ message }
							onChange={ event => setMessage(event.target.value) }
							placeholder="Please include any questions/enqueries. We look forward to hearing from you!"
						/>

						<Label htmlFor="medical-conditions">Medical conditions (if any)</Label>
						<TextArea
							name="medical-conditions"
							value={ medicalConditions }
							onChange={ event => setMedicalConditions(event.target.value) }
							placeholder="Please describe any medical conditions that you might have. Write 'None' if you don't have any."
						/>

						<Label htmlFor="social-media-url">Your social media URL (facebook, instagram, etc.)</Label>
						<Input
							name="social-media-url"
							type="text"
							value={ socialMediaUrl }
							onChange={ event => setSocialMediaUrl(event.target.value) }
							placeholder="ex. https://www.instagram.com/instagram/"
						/>

						<Label htmlFor="phone-number">Phone Number</Label>
						<Input
							name="phone-number"
							type="text"
							placeholder="ex. 888 888 8888"
							value={ phoneNumber }
							onChange={ event => setPhoneNumber(event.target.value) }
						/>
						<RequiredMessage style={{ marginBottom: 28 }}>
							<RequiredAsterisk /><RequiredAsterisk /> Phone screening required <RequiredAsterisk /><RequiredAsterisk />
						</RequiredMessage>

						<SubmitButton type="submit" disabled={ !isFormValid }>
							Send
						</SubmitButton>
					</form>
				</ContactSection>
			</ContactContainer>
		</Layout>
	)
}

// https://github.com/facebook/react/issues/8669
//const cachedMarker = (
//	<Marker position={{
//		lat: 40.748882,
//		lng: -73.944683
//	}} />
//)

const Split = styled.div`
	display: flex;
	justify-content: space-between;
`

const NameContainer = styled.div`
	width: 100%;
`

const AgeContainer = styled.div`
	max-width: 100px;
	margin-left: 10px;
`

const Bold = styled.span`
	font-weight: 550;
	color: #444;
`
const ContactContainer = styled(Container)`
	font-size: 1.5em;
	padding: 0 15px;
	display: grid;
	grid-gap: 40px;
	grid-template-columns: 1fr 1fr;
	@media (max-width: 720px) {
		grid-gap: 20px;
		grid-template-columns: 1fr;
	}
`
//const MapSection = styled.section`
//	height: 350px;
//	grid-column: 1/3;
//	@media (max-width: 720px) {
//		grid-column: 1/2;
//	}
//`
const DetailSection = styled.section`
	padding-left: 10px;
	@media (max-width: 720px) {
		padding-left: 0;
	}
`
const DetailIntroParagraph = styled.p`
	font-size: 1em;
`
const DetailContainer = styled.div`
	margin-bottom: 30px;
`
const Title = styled.h3`
	margin-bottom: 5px;
	color: hsl(356, 45%, 48%);
`
const ContactSection = styled.section`
	padding-right: 10px;
	@media (max-width: 720px) {
		padding-right: 0;
	}
`
const Label = styled.label`
	display: block;
	color: #555;
	margin-bottom: 5px;
	font-weight: 550;
`
const EnqueryLabel = styled(Label)`
	margin-bottom: 0;
`


const Enqueries = styled.div`
	padding-bottom: 15px;
`
const Enquery = styled.div`
	display: inline-block;
	padding-right: 12px;
`
const EnqueryType = styled.div`
	display: inline-block;
	vertical-align: middle;
	padding-right: 6px;
`
const EnqueryCheckbox = styled.input`
	vertical-align: middle;
	padding: 20px;
`
const sharedInputStyle = {
	display: 'block',
	width: '100%',
	border: '1px solid #555',
	marginBottom: 20,
	padding: 10,
	borderRadius: 5,
	':focus': {
		outline: 'none !important',
		border: '1px solid red',
		boxShadow: '0 0 15px rgba(100, 100, 100, 0.15)'
	}
}

const Input = styled.input(sharedInputStyle)
const TextArea = styled.textarea({
	...sharedInputStyle,
	minHeight: 130
})
const SubmitButton = styled.button(({ disabled }) => ({
	display: 'block',
	border: '1px solid #555',
	borderRadius: 4,
	padding: '5px 20px',
	backgroundColor: '#fff',
	float: 'right',
	color: 'rgb(85, 85, 85)',
	fontWeight: 600,
	cursor: 'pointer',
	':hover': {
		boxShadow: '0 0 15px rgba(100, 100, 100, 0.15)'
	},
	...disabled ? {
		opacity: .5,
		cursor: 'not-allowed',
		':hover': {
			boxShadow: 'none'
		}
	} : {}
}))

const RequiredAsterisk = React.memo(() => <Asterisk>*</Asterisk>)
const Asterisk = styled.span`
	color: red;
	font-size: 1.5em
`

const RequiredMessage = styled.div`
	margin-bottom: 14px;
	font-weight: bold;
    border: 1px solid red;
    padding: 14px;
    border-radius: 8px;
    text-align: center;
`

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
	return re.test(String(email).toLowerCase())
}

function validatePhoneNumber(phoneNumber) {
	if (phoneNumber === '') {
		return false
	}
	const re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
	return re.test(phoneNumber)
}

// https://tutorial.eyehunts.com/js/url-regex-validation-javascript-example-code/
function validateUrl(url) {
	var regexp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	return regexp.test(url);
}